<template>
  <!-- 历史数据连续性 -->
  <div class="historical-data-continuity" ref="HistoricalContinuityData">
    <div class="form-area" ref="form">
      <serchForm @getFormVal="onSearch">
        <el-button
          type="primary"
          size="small"
          :disabled="tableData.length === 0"
          :loading="downLoadStatus"
          @click="exportData"
          >导出</el-button
        >
      </serchForm>
    </div>
    <el-table id="outputTable" :data="tableData" stripe :height="tableHeight">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="companyName" label="所属企业"></el-table-column>
      <el-table-column prop="cph" width="110" label="车牌号"></el-table-column>
      <el-table-column
        prop="address"
        label="中断位置"
        width="450"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="timeInterruptSlot"
        show-overflow-tooltip
        label="中断时间"
      ></el-table-column>      
      <el-table-column
        prop="interruptTimeStr"
        label="中断时长"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="distance"
        label="行驶距离(km)"
        width="140"
      >
      <template slot-scope="scope">
        {{ scope.row.distance | filterKm }}
      </template>
      </el-table-column>
      <el-table-column label="操作" width="120">
        <template slot-scope="scope">
          <el-button
            @click="onViewDetail(scope.row)"
            type="text"
            size="small"
            sort="primary"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <!-- 详情 -->
    <el-dialog
      title="报警详情"
      v-if="detailDialog"
      :visible.sync="detailDialog"
      width="75%"
    >
      <div class="body">
        <historyDetailDialog
          :detailData="detailData"
          :queryRealType="form.queryRealType"
        ></historyDetailDialog>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import serchForm from "./components/serchForm.vue";
import { oneGpsToLngLat,formatDate,getCurent } from '@/common/utils/index'
import getPlace from '@/common/utils/getPlace.js'
import {
  queryTravelContinuousPageAPI,
} from "@/api/lib/gps-api.js";
import historyDetailDialog from './components/historyDetailDialog.vue';
import { addExcelExport } from '@/api/lib/refreshAlarm.js'
export default {
  components: {
    serchForm,
    historyDetailDialog
  },
  data () {
    return {
      tableData: [],
      tableHeight: 0,
      total: 0,
      form: {
        pageSize: 10,
        currentPage: 1,
        companyId:null,
      },
      downLoadStatus: false,
      detailDialog: false,
      detailData: null,
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  filters: {
    filterKm (val) {
      const kilometers = val / 1000;
        // 检查是否有小数部分
        if (Number.isInteger(kilometers)) {
          return kilometers.toString(); // 如果是整数，直接返回整数字符串
        } else {
          return kilometers.toFixed(2); // 如果有小数部分，保留两位小数
        }
    }
  },
  methods: {    
    exportData (key) {
      this.downLoadStatus = true;
      delete this.exportForm.currentPage;
      delete this.exportForm.pageSize;      
      let data = {
        baseUrl: "report",
        userId: sessionStorage.getItem("userId") || localStorage.getItem('userId'),
        createTime: formatDate(new Date()),
        fileName:`历史数据连续性-${getCurent()}`,
        filePath: null,
        fileStatus: 1,
        generateTime: null,
        queryParam: JSON.stringify(this.exportForm),
        queryPath:"/travelContinuous/travelContinuousExport",
      };
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    },
    onSearch (val) {
      //优化：所属车队只需要传单个值，不需要数组
      this.form = {
        ...val,
        companyId:val.companyId ? val.companyId[0] : null,
        currentPage: 1,
        pageSize: 10
      };
      this.getDataList();
    },
    cancelDialog () {
      this.detailDialog = false;
    },
    onViewDetail (row) {
      this.detailData = { ...row };
      this.detailDialog = true;
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page
      this.getDataList()
    },
    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size
      this.getDataList()
    },
    getDataList () {
      queryTravelContinuousPageAPI(this.form).then(res => {
        if (res.code === 1000) {
          let arr = []
          let tenArr = [...res.data.list];//前十条数据
          for (let i = 0; i < tenArr.length; i++) {
            let s = new Promise((resolve, reject) => {
              oneGpsToLngLat(tenArr[i].beginLongitude, tenArr[i].beginLatitude, true).then((res) => {
                getPlace(res[0], res[1]).then((res) => {
                  if (res) {
                    tenArr[i].address = res;                    
                    resolve(tenArr)
                  }
                })
              })
            })
            arr.push(s)
          }
          Promise.all(arr).then(res => {
            this.tableData = [ ...tenArr];
          });
          this.total = res.data.total;
        } else {
          this.tableData = [];
          this.total = 0;
        }
      })
      this.exportForm = { ...this.form };
    },
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.HistoricalContinuityData.clientHeight;
      const formHeight = this.$refs.form.clientHeight;
      const paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight =
        wholeHeight -
        16 -
        formHeight -
        paginationHeight -
        10;
    },
  },
  destroyed () {
    window.removeEventListener("resize", this.computeHeight);
  }
}
</script>
<style lang="scss" scoped>
.historical-data-continuity {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  /deep/.form-area .el-form .el-form-item .el-input__inner {
    width: 100%;
  }
  @include themify() {
  }
}
</style>